<template>
  <app-layout>
    <transition name="component-fade" mode="out-in">
      <div
        v-if="showClientForm"
        class="flex flex-col items-center py-4"
        key="form">
        <div class="w-full lg:w-1/2">
          <div class="flex flex-row items-center inline py-4">
            <sa-icon-button
              iconClass="bx bx-left-arrow-alt"
              @click="closeClientForm"
              with-bg />
            <span class="mx-2 text-lg font-bold">Cliente</span>
          </div>
          <content-card>
            <client-form
              v-if="isNewClient"
              @onSaved="savedClient"
              @onCancel="closeClientForm" />
            <client-form
              v-if="clientToUpdate && !isNewClient"
              :client="clientToUpdate"
              @onSaved="savedClient"
              @onCancel="closeClientForm" />
          </content-card>
        </div>
      </div>
      <div
        v-if="!showClientForm"
        class="flex flex-col items-center py-4"
        key="list">
        <div class="w-full px-12">
          <div class="flex flex-row items-center py-4">
            <h3 class="text-lg font-bold">Clientes</h3>
            <div class="flex flex-row items-center ml-auto">
              <el-button @click="goImportClients" class="config-button" :class="{ 'p-2' : isMobile }">
                <span v-if="!isMobile">Importar</span>
                <i v-if="isMobile" class='text-md bx bx-cog'></i>
              </el-button>
              <sa-button
                type="primary"
                class="items-center px-3 py-3 font-bold rounded"
                @click="createClient">
                Crear cliente
              </sa-button>
            </div>
          </div>
          <div class="flex flex-col space-y-6">
            <content-card>
              <clients-list @onEdit="updateClient"/>
            </content-card>
          </div>
        </div>
      </div>
    </transition>
  </app-layout>
</template>
<script>
import responsiveSize from '@/mixins/responsiveSize';
export default {
  name: 'ClientsIndex',
  mixins: [responsiveSize],
  components: {
    ClientForm: () => import('./ClientForm.vue'),
    ClientsList: () => import('./ClientsList.vue'),
    ContentCard: () => import('@/components/molecules/ContentCard.vue'),
    AppLayout: () => import('@/components/layouts/AppLayout.vue'),
  },
  data() {
    return {
      showClientForm: false,
      isNewClient: false,
      clientToUpdate: null,
      isMobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    savedClient() {
      this.showClientForm = false;
      this.$router.push({ name: "clients.index" });
      this.$toastr.s(this.isNewClient ? 'Se creo con exito' : 'Se actualizo con exito');
    },
    updateClient(clientToUpdate) {
      this.clientToUpdate = clientToUpdate;
      this.showClientForm = true;
    },
    createClient() {
      this.isNewClient = true;
      this.showClientForm = true;
    },
    closeClientForm() {
      this.showClientForm = false;
      this.isNewClient = false;
      this.clientToUpdate = null;
    },
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
    goImportClients() {
      const isOwner = this.$store.state.account.accountType === 1;
      const { canViewAdministration } = this.$store.state.account;
      if(isOwner || canViewAdministration){
        this.$router.push({ name: 'admin.index', params: { slot: 'upload-client-data' } });
        return;
      }
      this.$swal('Aviso',
        'No tienes el permiso para realizar esta acción ',
        'error')
    },
  },
}
</script>
<style scoped>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .2s ease;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
.config-button {
  padding: 12px 20px !important;
  @apply bg-blue-500 text-white;
}

.config-button:hover {
  @apply opacity-80;
}
</style>
